import './polyfills';
import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { NoMatch } from './NoMatch';
import { ErrorBoundary } from './ErrorBoundary';

import { LoadingIndicator } from './LoadingIndicator';
import { store as storePublicApp } from './application/configuration';
import { createRoot } from 'react-dom/client';

const PublicAppContainer = lazy(
    () => import(/* webpackChunkName: "PublicApp" */ './application/app/PublicApp.container')
);

const renderPublicApplication = (): void => {
    const container = document.getElementById('root');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    root.render(
        <ErrorBoundary>
            <Provider store={storePublicApp}>
                <BrowserRouter>
                    <Suspense fallback={LoadingIndicator}>
                        <Routes>
                            <Route path={'/error'} element={<NoMatch />} />
                            <Route path={'/'} element={<PublicAppContainer />} />
                            <Route element={<NoMatch />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
};

renderPublicApplication();
