import { get } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../model/general.types';
import { TrackingPosition, Position } from '../../model/trackingPosition.types';

const name = 'tracking';

export interface TrackingState {
    secret?: string;
    id?: string;
    trackingPosition?: TrackingPosition;
}

const initialState: TrackingState = {
    secret: undefined,
    id: undefined,
    trackingPosition: undefined,
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setSecret: (state: TrackingState, action: PayloadAction<string>): void => {
            state.secret = action.payload;
        },
        setId: (state: TrackingState, action: PayloadAction<string>): void => {
            state.id = action.payload;
        },
        setTrackingPosition: (state: TrackingState, action: PayloadAction<TrackingPosition>): void => {
            state.trackingPosition = action.payload;
        },
    },
});

export const trackingActions = actions;
export const trackingReducer = reducer;

const getBase = (state: State): TrackingState | undefined => get(state, name);
const getTrackingPosition = (state: State): TrackingState | undefined => get(getBase(state), 'trackingPosition');

export const getSecret = (state: State): string | undefined => get(getBase(state), 'secret', undefined);
export const getId = (state: State): string | undefined => get(getBase(state), 'id', undefined);

export const getTrackingName = (state: State): string | undefined => get(getTrackingPosition(state), 'name', undefined);
export const getTrackingStart = (state: State): string | undefined =>
    get(getTrackingPosition(state), 'start', undefined);
export const getTrackingEnd = (state: State): string | undefined => get(getTrackingPosition(state), 'end', undefined);
export const getTrackingComment = (state: State): string | undefined =>
    get(getTrackingPosition(state), 'comment', undefined);
export const getAssetName = (state: State): string | undefined =>
    get(getTrackingPosition(state), 'assetName', undefined);
export const getPosition = (state: State): Position | undefined =>
    get(getTrackingPosition(state), 'position', undefined);
