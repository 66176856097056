import { combineReducers, Reducer } from 'redux';
import { apiCallReducer } from '../domain/service/redux/api.redux';
import { trackingReducer } from '../domain/service/redux/tracking.redux';
import langReducer from './configuration/lang/reducer';
import configReducer from './configuration/setup/configReducer';
import { sidebarReducer } from '../domain/service/redux/sidebar.redux';

export const rootReducer = (): Reducer =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        api: apiCallReducer,
        tracking: trackingReducer,
        sidebar: sidebarReducer,
    });
