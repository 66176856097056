import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';
import has from 'lodash/fp/has';

const DEFAULT_LOCALE = 'en-GB';

const supportedLocaleMap = {
    'bg': 'bg-BG',
    'bg-BG': 'bg-BG',
    'cs': 'cs-CZ',
    'cs-CZ': 'cs-CZ',
    'da': 'da-DK',
    'da-DK': 'da-DK',
    'de': 'de-DE',
    'de-DE': 'de-DE',
    'el': 'el-GR',
    'el-GR': 'el-GR',
    'en': 'en-GB',
    'en-GB': 'en-GB',
    'es': 'es-ES',
    'es-ES': 'es-ES',
    'et': 'et-EE',
    'et-EE': 'et-EE',
    'fi': 'fi-FI',
    'fi-FI': 'fi-FI',
    'fr': 'fr-FR',
    'fr-FR': 'fr-FR',
    'hr': 'hr-HR',
    'hr-HR': 'hr-HR',
    'hu': 'hu-HU',
    'hu-HU': 'hu-HU',
    'it': 'it-IT',
    'it-IT': 'it-IT',
    'ko': 'ko-KR',
    'ko-KR': 'ko-KR',
    'lt': 'lt-LT',
    'lt-LT': 'lt-LT',
    'lv': 'lv-LV',
    'lv-LV': 'lv-LV',
    'nb': 'nb-NO',
    'nb-NO': 'nb-NO',
    'nl': 'nl-NL',
    'nl-NL': 'nl-NL',
    'pl': 'pl-PL',
    'pl-PL': 'pl-PL',
    'pt': 'pt-PT',
    'pt-BR': 'pt-BR',
    'pt-PT': 'pt-PT',
    'ro': 'ro-RO',
    'ro-RO': 'ro-RO',
    'sk': 'sk-SK',
    'sk-SK': 'sk-SK',
    'sl': 'sl-SI',
    'sl-SI': 'sl-SI',
    'sv': 'sv-SE',
    'sv-SE': 'sv-SE',
};

const extractLanguage = flow(defaultTo(DEFAULT_LOCALE), split('-'), head);

const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

const getSupportedLocale = (preferredLocale) =>
    has(preferredLocale, supportedLocaleMap) ? supportedLocaleMap[preferredLocale] : DEFAULT_LOCALE;

export { DEFAULT_LANG, DEFAULT_LOCALE, extractLanguage, supportedLocaleMap, getSupportedLocale };
