import { get } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../model/general.types';

export interface TrackingSidebarState {
    isTrackingSidebarOpen: boolean;
}

const initialState: TrackingSidebarState = {
    isTrackingSidebarOpen: true,
};

const name = 'sidebar';

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setIsTrackingSidebarOpen: (state: TrackingSidebarState, action: PayloadAction<boolean>): void => {
            state.isTrackingSidebarOpen = action.payload;
        },
    },
});

export const sidebarActions = actions;
export const sidebarReducer = reducer;

const getBase = (state: State): TrackingSidebarState | undefined => get(state, name);
export const getIsTrackingSidebarOpen = (state: State): boolean =>
    get(getBase(state), 'isTrackingSidebarOpen') ?? false;
