import { ApiCalls, ErrorCauses } from '../../model/api.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { State } from '../../model/general.types';

export interface ApiLoadingState {
    loading: { [key: string]: boolean };
    error: { [key: string]: boolean };
}

const initialState: ApiLoadingState = {
    loading: {
        [ApiCalls.POSITION]: false,
    },
    error: {
        [ErrorCauses.POSITION_FETCH]: false,
    },
};

const name = 'api';

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        setLoadingForApiCall: (
            state: ApiLoadingState,
            action: PayloadAction<{ type: ApiCalls; value: boolean }>
        ): void => {
            state.loading = {
                ...state.loading,
                [action.payload.type]: action.payload.value,
            };
        },
        setErrorForApiCall: (
            state: ApiLoadingState,
            action: PayloadAction<{ type: ErrorCauses; value: boolean }>
        ): void => {
            state.error = {
                ...state.error,
                [action.payload.type]: action.payload.value,
            };
        },
    },
});

export const apiCallActions = actions;
export const apiCallReducer = reducer;

const getBase = (state: State): ApiLoadingState | undefined => get(state, 'api');
const getLoading = (state: State): { [key: string]: boolean } | undefined => get(getBase(state), 'loading');
const getError = (state: State): { [key: string]: boolean } | undefined => get(getBase(state), 'error');

export const getIsPositionLoading = (state: State): boolean => get(getLoading(state), ApiCalls.POSITION, false);

export const getHasPositionFetchError = (state: State): boolean =>
    get(getError(state), ErrorCauses.POSITION_FETCH, false);
